<template>
  <div class="relative">

    <div class="min-h-[60vh] flex justify-between pt-8 items-center flex-col bg-gradient-to-b from-transparent to-limed-spruce-900">
      <HomeProfileCard :loading="loading"></HomeProfileCard>


      <HomeAnnouncement class="duration-200" :announcements="announcements"></HomeAnnouncement>

    </div>



    <div class="w-full p-4 flex justify-center items-center flex-col space-y-8">
      <div v-for="project in projects?.active?.projects" class="border flex-shrink-0 border-white/5 duration-300 bg-white/5 backdrop-blur-sm hover:bg-white hover:bg-opacity-[5%] w-full hover:shadow-2xl shadow-xl shadow-black/20 hover:border-white/50 rounded-xl">
        <HomeBannerProject :categoryLabel="'Live Now'" :project="project"></HomeBannerProject>
      </div>

      <div v-for="project in projects?.upcoming?.projects" class="border flex-shrink-0 border-turquoise-500/20 duration-300 bg-white/5 backdrop-blur-sm hover:bg-white hover:bg-opacity-[5%] w-full hover:shadow-2xl shadow-xl shadow-black/20 hover:border-turquoise-500/50 rounded-xl">
        <HomeBannerProject :categoryLabel="'Upcoming'" :project="project"></HomeBannerProject>
      </div>
    </div>

   <div class="w-full">
      <HomeCalendarPane></HomeCalendarPane>
    </div>


    <div class="px-4">
      <HomeBoard :posts="posts"></HomeBoard>
    </div>

    <HomeTiles :projects="projects"></HomeTiles>


  </div>
</template>

<script setup>
const props = defineProps(["projects", "posts", "announcements","loading", "address"]);
const { projects, posts, announcements, loading, address } = toRefs(props);
</script>

<style></style>
